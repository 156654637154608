import * as React from "react";

import Icon from "./Icon";
import Menu from "@mui/material/Menu";
import Text from "./Text";
import { colors } from "utils/color";
import { get } from "lodash";
import styled from "styled-components";
import { useState } from "react";

const Container = styled.div`
  position: relative;
`;

const Option = styled.div`
  font-size: 16px;
  padding: 12px;
  color: ${colors.grey};
  border-top: ${(p) => (p.showBorder ? "1px" : "0px")} solid ${colors.border};
  display: flex;
  gap: 8px;
  align-items: center;
  cursor: pointer;
  &:hover {
    background: #f8fafb;
  }
`;

const Dropdown = ({ icon, component, options = [] }) => {
  const [expanded, setExpanded] = useState(false);

  const [closeCounter, setCloseCounter] = useState(0);

  const showOnHover = false;

  const getTriggerComponent = () => {
    if (component) {
      return component;
    }

    // if (data.text) {
    //   return <Text {...text} />;
    // }

    if (icon) {
      return <Icon {...icon} />;
    }

    // if (data.button) {
    //   return (
    //     <Button
    //       data={{
    //         ...data.button,
    //         icon:
    //           data.button.icon || (expanded ? "FiChevronUp" : "FiChevronDown"),
    //       }}
    //     />
    //   );
    // }
  };

  return (
    <Container
      onMouseEnter={() => {
        if (showOnHover) {
          setExpanded(true);
        }
      }}
      onMouseLeave={() => {
        if (showOnHover) {
          setExpanded(false);
        }
      }}
    >
      <MuiDropdown
        triggerComponent={getTriggerComponent()}
        setExpanded={setExpanded}
        closeCounter={closeCounter}
      >
        {options.map((option, i) => (
          <Option
            id={`simpleDropdown-option-${i}`}
            key={i}
            onClick={(e) => {
              option.onClick(e);
              setCloseCounter(closeCounter + 1);
            }}
          >
            <Text
              value={option.label}
              size={14}
              whiteSpace="nowrap"
              cursor="pointer"
            />
          </Option>
        ))}
      </MuiDropdown>
    </Container>
  );
};

export default Dropdown;

const MuiDropdown = ({
  triggerComponent,
  setExpanded = null,
  children,
  closeCounter = 0,
  width,
}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  // Show if anchor element is not null
  const open = Boolean(anchorEl);

  const handleOpen = (e) => {
    setAnchorEl(e.currentTarget);
    setExpanded && setExpanded(true);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setExpanded && setExpanded(false);
  };

  // Prevent annoying click propagation
  const onClickAway = (evt) => {
    evt.preventDefault();
    evt.stopPropagation();
  };

  // Enable forced closes from parent
  React.useEffect(() => {
    if (closeCounter > 0) {
      setAnchorEl(null);
    }
  }, [closeCounter]);

  return (
    <div>
      <div onClick={(e) => e.stopPropagation()}>
        <div onClick={handleOpen}>{triggerComponent}</div>
      </div>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        onBlur={onClickAway}
        onClick={onClickAway}
        style={{ zIndex: 9999, width }}
      >
        {children}
      </Menu>
    </div>
  );
};
