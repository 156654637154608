import { colors } from "utils/color";
import styled from "styled-components";

const TextArea = ({ value, onChange, ...props }) => {
  return (
    <StyledTextArea
      onChange={(e) => onChange(e.target.value)}
      value={value}
      placeholder={props.placeholder}
      disabled={props.disabled}
      border={props.border}
      minHeight={props.minHeight}
    />
  );
};

const StyledTextArea = styled.textarea`
  padding: 12px;
  border: ${(p) => p.border || `2px solid ${colors.inputBorder}`};
  border-radius: 8px;
  width: ${(p) => p.width || "100%"};
  min-height: ${(p) => p.minHeight || "auto"};
  font-size: ${(p) => p.fontSize || "16px"};
`;

export default TextArea;
