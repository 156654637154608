import { useLocation, useNavigate } from "react-router-dom";

import { Button } from "components";
import PublicWrapper from "./components/PublicWrapper";
import Text from "components/Text";
import { colors } from "utils/color";
import { getUrlParameter } from "utils/utils";
import styled from "styled-components";

const VerifyEmail = () => {
  const location = useLocation();
  const status = getUrlParameter("status", location);
  const navigate = useNavigate();

  return (
    <PublicWrapper>
      {status === "verified" ? (
        <Container>
          <Logo>Formattr</Logo>
          <Text value="Email Verified" textAlign="center" size={28} bold />
          <Text
            value="Login to access your account"
            textAlign="center"
            size={24}
            light
          />
          <Button
            text="Click To Login"
            size="large"
            style={{ margin: "20px 0 0 0" }}
            onClick={() => navigate("/login")}
          />
        </Container>
      ) : (
        <Container>
          <Logo>Formattr</Logo>
          <Text value="Verify Your Email" textAlign="center" size={28} bold />
          <Text
            value="To access your account, please check your email for a verification link"
            size={24}
            light
            textAlign="center"
          />
        </Container>
      )}
    </PublicWrapper>
  );
};

export default VerifyEmail;

const Container = styled.div`
  width: 400px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
`;

const Logo = styled.div`
  font-size: 52px;
  font-family: "DM Serif Display", serif;
  font-weight: 400;
  color: ${colors.primary};
`;
