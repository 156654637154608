import { colors } from "utils/color";
import styled from "styled-components";

const SwitchWrapper = styled.div`
  background: ${(p) => (p.active ? colors.primary : colors.darkBeige)};
  width: 32px;
  cursor: pointer;
  border-radius: 100px;
  border: 2px solid ${(p) => (p.active ? colors.primary : colors.darkBeige)};
  padding: 1px;
  display: flex;
  justify-content: ${(p) => (p.active ? "flex-end" : "flex-start")};
  transition: 0.4s;
  ${(p) =>
    p.disabled &&
    `background: ${colors.grey}; cursor: not-allowed; border: 1px solid ${colors.grey}`}

  ${(p) =>
    !p.disabled &&
    `
      &:hover {
        filter: brightness(90%);
      }
  `}
`;

const SwitchBall = styled.div`
  background: white;
  border-radius: 100px;
  height: 12px;
  width: 12px;
  padding: 1px;
`;

const parseBoolean = (value) => {
  if (typeof value === "boolean") return value;

  if (typeof value === "string") {
    const trimmedValue = value.trim().toLowerCase();

    switch (trimmedValue) {
      case "true":
        return true;
      case "false":
        return false;
      default:
        return false;
    }
  }

  return false;
};

const Switch = ({ disabled, value, onChange }) => {
  const isActive = parseBoolean(value);

  return (
    <SwitchWrapper
      disabled={disabled}
      onClick={
        disabled
          ? null
          : (e) => {
              e.stopPropagation();
              onChange(!isActive);
            }
      }
      active={isActive}
    >
      <SwitchBall active={isActive} />
    </SwitchWrapper>
  );
};

export default Switch;
