import { Button, FullScreenModal, Icon, Row, Text } from "components";

import styled from "styled-components";

const ConfirmationModal = ({ label, description, hide, onConfirm }) => {
  return (
    <FullScreenModal label={label} hide={hide} zIndex={1000}>
      <Container>
        <Icon icon="FiAlertCircle" color="#f79e44" size={60} />
        <Text value={description} textAlign="center" size={18} />
        <Row gap="15px">
          <Button text="Confirm" onClick={onConfirm} />
          <Button text="Cancel" type="hollow" onClick={hide} />
        </Row>
      </Container>
    </FullScreenModal>
  );
};

export default ConfirmationModal;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
`;
