import Button from "./Button";
import Icon from "./Icon";
import Input from "./Input";
import Text from "./Text";
import { colors } from "utils/color";
import styled from "styled-components";

const KeyValuePairs = ({ label, value, onChange, margin }) => {
  const addField = () => {
    const newValue = [...value, { key: "", value: "" }];
    onChange(newValue);
  };

  const editField = (index, k, v) => {
    onChange(
      value.map((field, fieldIndex) => {
        if (fieldIndex === index) {
          return { ...field, [k]: v };
        }
        return field;
      })
    );
  };

  const deleteField = (index) => {
    onChange(value.filter((_, fieldIndex) => fieldIndex !== index));
  };

  return (
    <div style={{ margin }}>
      <Container>
        <Row>
          <HeaderCell
            style={{
              borderRight: `1px solid ${colors.inputBorder}`,
            }}
          >
            Key
          </HeaderCell>
          <HeaderCell>Value</HeaderCell>
          <HeaderCell
            style={{
              flex: "none",
              width: "35px",
            }}
          ></HeaderCell>
        </Row>
        {value.map((field, index) => (
          <Row>
            <Input
              value={field.key}
              placeholder={"Key"}
              onChange={(v) => editField(index, "key", v)}
              border={`2px solid ${colors.inputBorder}`}
              borderradius="0px"
              borderwidth="0px 1px 0px 0px"
            />
            <Input
              value={field.value}
              placeholder={"Value"}
              onChange={(v) => editField(index, "value", v)}
              border="0px"
              borderradius="0px"
            />
            <Cell>
              <Icon
                icon="FiTrash"
                color={"#bab7a6"}
                onClick={() => deleteField(index)}
                hover
                size={18}
              />
            </Cell>
          </Row>
        ))}
      </Container>
      {!value.length && (
        <Text value={`No ${label} added`} margin="10px 0 0 0" />
      )}
      <Button
        text="Add"
        size="small"
        icon="FiPlus"
        style={{ margin: "10px 0 0 0" }}
        onClick={addField}
      />
    </div>
  );
};

export default KeyValuePairs;

const Container = styled.div`
  border: 2px solid ${colors.inputBorder};
  border-radius: 8px;
  overflow: hidden;
  background-color: rgba(255, 255, 255, 0.5);
`;

const Row = styled.div`
  display: flex;
  border-bottom: 1px solid ${colors.inputBorder};
  &:last-child {
    border-bottom: none;
  }
`;

const HeaderCell = styled.div`
  flex: 1;
  font-size: 13px;
  font-weight: 600;
  padding: 7px 0 7px 10px;
  color: ${colors.darkGrey};
`;

const Cell = styled.div`
  min-width: 35px;
  border-left: 1px solid ${colors.inputBorder};
  display: flex;
  align-items: center;
  justify-content: center;
`;
