import { Icon, Text } from "components";

import { colors } from "utils/color";
import styled from "styled-components";

const FullScreenModal = ({ label, hide, children, zIndex = 999 }) => {
  return (
    <FullScreen zIndex={zIndex}>
      <AppContainer>
        <Icon
          icon="FiX"
          onClick={hide}
          color={colors.darkGrey}
          size={30}
          hover
          margin="0 0 0 -4px"
        />
        <Text value={label} extraBold size={30} margin="30px 0 40px 0" />
        {children}
      </AppContainer>
    </FullScreen>
  );
};

export default FullScreenModal;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const FullScreen = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: auto;
  z-index: ${(p) => p.zIndex};
  background: ${colors.background};
`;

const AppContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 1100px;
  margin: 0 auto;
  padding-top: 50px;
  padding-bottom: 50px;
`;
