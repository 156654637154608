import { get, snakeCase } from "lodash";

export const getAiRequestObject = (
  request,
  data,
  isArray = false,
  showVariableIfNull = false
) => {
  if (!request) {
    return {};
  }

  const resolvedPrompt = processDynamicText(
    request.prompt,
    data,
    showVariableIfNull
  );

  const fields = get(request, "fields", []) || [];

  const functionName = snakeCase(request.name);
  const fieldKeys = fields.map((field) => field.key);

  const properties = {};

  fields.forEach((field) => {
    const { key, type = "string", value } = field;
    const fieldKey = snakeCase(key);
    properties[fieldKey] = {
      type,
      description: value,
    };
  });

  const objectStructure = {
    type: "object",
    properties,
    required: fieldKeys,
  };

  let obj = { name: functionName };

  if (isArray) {
    // ARRAY OF OBJECTS
    obj["parameters"] = {
      type: "object",
      required: ["items"],
      properties: {
        items: {
          type: "array",
          items: objectStructure,
        },
      },
    };
  } else {
    // SINGLE OBJECT
    obj["parameters"] = objectStructure;
  }

  let returnObj = {
    function: obj,
    prompt: resolvedPrompt,
    original_prompt: get(request, "prompt"),
    examples: get(request, "examples", []) || [],
  };

  if (isArray) {
    returnObj = {
      ...returnObj,
      is_array: request.is_array,
      array_min_length: request.array_min_length,
      array_max_length: request.array_max_length,
    };
  }

  return returnObj;
};

export const processDynamicText = (text, data, showVariableIfNull = false) => {
  if (!text) return text;
  // Updated pattern to match your simpler variable format
  const pattern = /\{\{\s*(.*?)\s*\}\}/g;

  return text.toString().replace(pattern, (match, key) => {
    // Directly accessing the property using key
    const value = data[key];
    // Using "None" as fallback if the key doesn't exist in data
    const fallback = showVariableIfNull ? `{{ ${key} }}` : "None";

    return value !== undefined ? value : fallback;
  });
};
