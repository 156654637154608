import { Button, Form, Icon, Row, Spinner, Text } from "components";
import { get, isEmpty, startCase } from "lodash";
import { getAiRequestObject, processDynamicText } from "utils/aiUtils";
import { getUrlParameter, getVariablesFromString } from "utils/utils";
import { rRunId, rUser, rWebsocket, rWebsocketRequests } from "utils/recoil";
import { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";

import CSVUpload from "components/CSVUpload";
import InternalWrapper from "components/InternalWrapper";
import Table from "components/Table";
import { apiRequest } from "utils/api";
import { colors } from "utils/color";
import parse from "html-react-parser";
import styled from "styled-components";
import { successNotification } from "utils/notification";
import useWebsocket from "utils/useWebsocket";
import { v4 as uuidv4 } from "uuid";

const Session = () => {
  const websocket = useRecoilValue(rWebsocket);
  const [websocketRequests, setWebsocketRequests] =
    useRecoilState(rWebsocketRequests);

  const setRunId = useSetRecoilState(rRunId);

  const websocketIsOpen = websocket && websocket.readyState === 1;

  const user = useRecoilValue(rUser);

  const { id } = useParams();

  const location = useLocation();

  const page = getUrlParameter("page", location) || 1;

  const pageCount = 10;

  const navigate = useNavigate();

  const setPage = (v) => {
    navigate(`?page=${v}`);
  };

  const [session, setSession] = useState(null);
  const [isFetching, setIsFetching] = useState(false);
  const [isGenerating, setIsGenerating] = useState(false);
  const [manualData, setManualData] = useState({});

  const request = get(session, "request", {});

  const [tableData, setTableData] = useState(null);

  const columnMapping = get(tableData, "column_mapping", {});

  const [changeObj, setChangeObj] = useState({});

  const variables = getVariablesFromString(get(request, "prompt", ""));

  const requestRef = useRef(request);
  const generatingRef = useRef(isGenerating);
  const manualDataRef = useRef(manualData);

  const method = get(session, "type", null);
  const isCsv = method === "csv";
  const isArray = !isCsv && request.is_array;

  // Update the refs whenever the state changes
  useEffect(() => {
    generatingRef.current = isGenerating;
  }, [isGenerating]);

  useEffect(() => {
    manualDataRef.current = manualData;
  }, [manualData]);

  useEffect(() => {
    requestRef.current = manualData;
  }, [request]);

  const aiRequestObject = getAiRequestObject(
    request,
    manualData,
    isArray,
    true
  );

  const prompt = get(aiRequestObject, "prompt", "");

  const promptRef = useRef(prompt);

  useEffect(() => {
    promptRef.current = prompt;
  }, [prompt]);

  const results = get(session, "results", []);

  const saveResult = (messageData) => {
    const formattedResponse = get(messageData, "data", {});
    const resultUuid = get(messageData, "uuid");
    const resultUsage = get(messageData, "usage", {});

    const items = get(formattedResponse, "items", []);

    if (items.length > 0) {
      // HANDLE ARRAY RESPONSE

      const finalItems = items.map((i) => ({ ...i, ...manualDataRef.current }));

      setTableData((previousData) => ({
        ...previousData,
        rows: [...get(previousData, "rows", []), ...finalItems],
      }));

      setIsGenerating(false);
    } else {
      // HANDLE SINGLE RESPONSE
      setTableData((previousData) => ({
        ...previousData,
        rows: get(previousData, "rows", []).map((r) => {
          if (r.f_uuid === resultUuid) {
            return {
              ...r,
              ...formattedResponse,
              f_selected: false,
            };
          }
          return r;
        }),
      }));

      setIsGenerating(false);

      let finalData = formattedResponse;
      if (manualDataRef.current) {
        finalData = {
          ...manualDataRef.current,
          ...formattedResponse,
        };
      }

      apiRequest.post("/result_update/", {
        session_id: id,
        uuid: resultUuid,
        data: finalData,
        usage: resultUsage,
      });

      const newResult = {
        uuid: resultUuid,
        data: finalData,
        usage: resultUsage,
        session: id,
        prompt: get(request, "prompt"),
      };

      // Update session results
      setSession((prev) => {
        const currentResults = get(prev, "results", []);

        // Update if already exists
        if (currentResults.find((r) => r.uuid === resultUuid)) {
          return {
            ...prev,
            results: currentResults.map((r) => {
              if (r.uuid === resultUuid) {
                return newResult;
              }
              return r;
            }),
          };
        } else {
          return {
            ...prev,
            results: [...currentResults, newResult],
          };
        }
      });
    }
  };

  useWebsocket(id, saveResult);

  const runManualRequest = () => {
    // Generate a unique run ID
    const runId = uuidv4();
    setRunId(runId);

    const newUuid = uuidv4();
    const tasks = [
      {
        action: "formattr_sqs",
        uuid: newUuid,
        data: getAiRequestObject(request, manualData, isArray, true),
      },
    ];

    if (!get(request, "is_array", false)) {
      setTableData((p) => ({
        ...p,
        rows: [
          ...get(tableData, "rows", []),
          {
            ...manualData,
            f_is_generating: true,
            f_uuid: newUuid,
          },
        ],
      }));
    }

    runRequest(runId, tasks);
  };

  const runBatchRequest = () => {
    // Generate a unique run ID
    const runId = uuidv4();
    setRunId(runId);

    const tasks = get(tableData, "rows", [])
      .filter((r) => r.f_selected && !r.f_has_result)
      .map((t) => {
        let obj = {};
        variables.forEach((v) => {
          const variableKey = get(columnMapping, v);
          obj[v] = get(t, variableKey);
        });

        return {
          action: "formattr_sqs",
          uuid: t.f_uuid,
          run_id: runId,
          session_id: id,
          original_prompt: get(request, "prompt"),
          data: getAiRequestObject(request, obj, isArray, true),
        };
      });

    const uuids = tasks.map((t) => t.uuid);

    setTableData((p) => ({
      ...p,
      rows: get(p, "rows", []).map((r) => {
        if (uuids.includes(r.f_uuid)) {
          return {
            ...r,
            f_is_generating: true,
          };
        }

        return r;
      }),
    }));

    runRequest(runId, tasks);
  };

  const runRequest = (runId, tasks) => {
    const finalTasks = tasks.map((t) => ({
      uuid: t.uuid,
      run_id: runId,
      session: id,
      prompt: t.data.prompt,
    }));

    if (!isArray) {
      // 1. Save results to DB
      apiRequest.post("/results_bulk_create/", {
        session_type: method,
        session_id: id,
        csv_data: tableData,
        name: get(session, "name"),
        results: finalTasks,
      });
    }

    // Add these specific task uuids to the websocket requests
    setWebsocketRequests([...websocketRequests, ...finalTasks]);

    // Send tasks to SQS via websocket
    websocket.send(
      JSON.stringify({
        action: "formattr_sqs",
        tasks,
      })
    );
  };

  const getSession = async () => {
    setIsFetching(true);

    const sessionRes = await apiRequest.get(`/session_details/?id=${id}`);
    const sessionData = get(sessionRes, "data", {});

    setIsFetching(false);

    setSession(sessionData);
    const csvData = get(sessionData, "csv_data");

    if (get(sessionData, "type") === "manual") {
      // MANUAL
      const mockRows = get(sessionData, "results", []).map((r) => ({
        f_uuid: r.uuid,
      }));

      setTableData({ ...csvData, rows: mockRows });
    } else {
      // CSV
      setTableData(csvData);
    }

    setIsFetching(false);
  };

  useEffect(() => {
    if (user && !session) {
      getSession();
    }
  }, [user]);

  const methods = [
    {
      id: "manual",
      label: "Manual",
      icon: "FiEdit",
      description: "Enter the inputs manually in a form",
    },
    {
      id: "csv",
      label: "CSV File",
      icon: "FiFileText",
      description: "Run the request on rows in a CSV file",
    },
  ];

  const save = () => {
    setIsFetching(true);
    apiRequest.post("/session_details/", { ...changeObj, id });
    setIsFetching(false);
    successNotification("Changes saved");
    setChangeObj({});
  };

  const buttons = isEmpty(changeObj)
    ? []
    : [
        {
          text: "Save Changes",
          onClick: save,
          disabled: isEmpty(changeObj),
        },
      ];

  const pageSize = 50;

  const pageRows = get(tableData, "rows", []).slice(
    (page - 1) * pageSize,
    page * pageSize
  );

  const tableUuids = pageRows.map((r) => r.f_uuid);

  const matchingResults = results.filter((r) => tableUuids.includes(r.uuid));

  const fields = get(request, "fields", []) || [];
  const tableColumns = [...variables, ...fields.map((f) => f.key)];

  const tableRows = get(tableData, "rows", []).map((r) => {
    let obj = { ...r };

    if (method === "csv") {
      variables.forEach((v) => {
        const variableKey = get(columnMapping, v);
        if (variableKey) {
          obj[v] = get(r, variableKey);
        }
      });
    }

    const matchingResult = matchingResults.find((mr) => mr.uuid === r.f_uuid);

    const matchingResultData = get(matchingResult, "data", {});

    obj = {
      ...obj,
      ...matchingResultData,
      f_has_result: !isEmpty(matchingResultData),
    };

    return obj;
  });

  const pageRows2 = tableRows.slice((page - 1) * pageSize, page * pageSize);

  const showResults = tableRows.length > 0;

  const headers = get(tableData, "headers", []);

  const downloadTableData = () => {
    const combinedHeaders = Array.from(new Set([...headers, ...tableColumns]));

    // Create CSV header row by escaping double quotes and joining with commas
    const headerRow = combinedHeaders
      .map((column) => `"${column.replace(/"/g, '""')}"`)
      .join(",");

    // Filter rows to include only those that are selected or have results
    const filteredRows = tableRows.filter(
      (row) => row.f_selected || row.f_has_result
    );

    // Map each filtered row to a CSV formatted string
    const dataRows = filteredRows.map((row) => {
      return combinedHeaders
        .map((column) => {
          const cellValue = get(row, column, "").toString();
          return `"${cellValue.replace(/"/g, '""')}"`; // Escape double quotes
        })
        .join(","); // Join each cell value with a comma
    });

    // Combine header and data rows into a single array
    const csvRows = [headerRow, ...dataRows];

    const csvString = csvRows.join("\n");

    const csvContent = `data:text/csv;charset=utf-8,${csvString}`;

    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "results.csv");
    document.body.appendChild(link); // Required for FF (Firefox)
    link.click();
  };

  const selectedRowCount = tableRows.filter((r) => r.f_selected).length;

  const completedRows = tableRows.filter((r) => r.f_has_result);

  const [currentResult, setCurrentResult] = useState(0);

  const [resultsView, setResultsView] = useState("table");

  return (
    <InternalWrapper
      isTitleEditable={true}
      onTitleChange={(v) => {
        setSession({ ...session, name: v });
        setChangeObj({ ...changeObj, name: v });
      }}
      titlePlaceholder="New Request"
      backText={get(request, "name", "")}
      title={get(session, "name", "")}
      backRoute={`/request/${get(request, "id")}?tab=sessions`}
      buttons={buttons}
    >
      <Container>
        {isFetching && <Spinner color={colors.primary} />}
        {!isFetching && request && (
          <>
            {!method && (
              <div>
                <Text
                  value="How do you want to run this request?"
                  color={colors.darkGrey}
                  size={30}
                  cursor="pointer"
                  margin="0 0 30px 0"
                />

                <Row gap="30px">
                  {methods.map((m) => (
                    <MethodOption
                      onClick={() => {
                        setSession({
                          ...session,
                          type: m.id,
                        });
                        setChangeObj({
                          ...changeObj,
                          type: m.id,
                        });
                      }}
                    >
                      <Icon icon={m.icon} color={colors.darkGrey} size={25} />
                      <Text
                        value={m.label}
                        color={colors.darkGrey}
                        size={30}
                        extraBold
                        cursor="pointer"
                      />
                      <Text
                        value={m.description}
                        color={colors.darkGrey}
                        size={22}
                        cursor="pointer"
                      />
                    </MethodOption>
                  ))}
                </Row>
              </div>
            )}
            {method && (
              <div>
                {method === "csv" && (
                  <div>
                    {tableData && (
                      <div>
                        <>
                          <Text
                            value="CSV Column Mapping"
                            size={20}
                            bold
                            cursor="pointer"
                            margin="0 0 10px 0"
                          />
                          <Text
                            value="For each input variable in your prompt, select the corresponding column in the CSV file."
                            size={16}
                            cursor="pointer"
                            margin="0 0 20px 0"
                          />
                          <Form
                            gridLayout={true}
                            onChange={(k, v) => {
                              const newTableData = {
                                ...tableData,
                                column_mapping: { ...columnMapping, [k]: v },
                              };
                              setTableData(newTableData);
                              setChangeObj({
                                ...changeObj,
                                csv_data: newTableData,
                              });
                            }}
                            value={columnMapping}
                            fields={variables.map((v) => ({
                              id: v,
                              component: "Select",
                              placeholder: `Enter ${startCase(v)}`,
                              options: headers.map((h) => ({
                                label: h,
                                value: h,
                              })),
                            }))}
                          />
                          <Button
                            text={
                              selectedRowCount === 0
                                ? "Row Selection Required"
                                : `Run Request On ${selectedRowCount} Row${
                                    selectedRowCount === 1 ? "" : "s"
                                  }`
                            }
                            isFetching={isGenerating}
                            disabled={
                              !websocketIsOpen ||
                              isEmpty(columnMapping) ||
                              selectedRowCount === 0
                            }
                            style={{ margin: "20px 0 0 0" }}
                            onClick={runBatchRequest}
                          />
                        </>
                      </div>
                    )}

                    {!tableData && (
                      <CSVUpload
                        onUpload={(parsedTableData) => {
                          const headers = get(
                            parsedTableData,
                            "headers",
                            []
                          ).map((h) => h.toLowerCase());

                          // Create column mapping from matching variables / headers
                          let columnMapping = {};
                          variables
                            .filter((v) => headers.includes(v.toLowerCase()))
                            .forEach((v) => {
                              columnMapping[v] = v;
                            });

                          setIsFetching(true);
                          apiRequest.post("/session_details/", {
                            csv_data: {
                              ...parsedTableData,
                              column_mapping: columnMapping,
                            },
                            type: method,
                            id,
                          });
                          successNotification("Changes saved");
                          setChangeObj({});
                          setTableData({
                            ...parsedTableData,
                            column_mapping: columnMapping,
                          });
                          setIsFetching(false);
                        }}
                      />
                    )}
                  </div>
                )}
                {(!method || method === "manual") && (
                  <>
                    {variables.length > 0 && (
                      <>
                        <Text
                          value={"Inputs"}
                          size={20}
                          bold
                          cursor="pointer"
                          margin="0 0 20px 0"
                        />
                        <Form
                          onChange={(k, v) => {
                            setManualData({ ...manualData, [k]: v });
                          }}
                          value={manualData}
                          fields={variables.map((v) => ({
                            id: v,
                            placeholder: `Enter ${startCase(v)}`,
                          }))}
                        />
                      </>
                    )}
                    <Text
                      value="Prompt Preview"
                      size={20}
                      bold
                      cursor="pointer"
                      margin="20px 0 10px 0"
                    />
                    <Text value={prompt} size={18} cursor="pointer" />
                    <Button
                      text={"Run Request"}
                      isFetching={isGenerating}
                      disabled={variables.length > 0 && isEmpty(manualData)}
                      style={{ margin: "30px 0 0 0" }}
                      onClick={runManualRequest}
                    />
                  </>
                )}

                {showResults && (
                  <>
                    <Row
                      justifyContent="space-between"
                      alignItems="center"
                      margin="30px 0 20px 0"
                    >
                      <Text value="Results" size={30} bold cursor="pointer" />
                      <Row
                        justifyContent="space-between"
                        alignItems="center"
                        gap="10px"
                      >
                        <Button
                          text={`Show ${
                            resultsView === "table" ? "Single" : "Table"
                          } View`}
                          // icon={"FiFileText"}
                          type="hollow"
                          onClick={() => {
                            setResultsView(
                              resultsView === "table" ? "single" : "table"
                            );
                          }}
                        />
                        <Button
                          text={"Download CSV"}
                          onClick={() => {
                            // Download tableData as CSV
                            downloadTableData();
                          }}
                        />
                      </Row>
                    </Row>

                    {resultsView === "single" ? (
                      <div>
                        <Row justifyContent="space-between" alignItems="center">
                          <Text
                            value={`Result ${currentResult + 1} of ${
                              completedRows.length
                            }`}
                            size={20}
                            bold
                            cursor="pointer"
                          />

                          <Row
                            justifyContent="space-between"
                            alignItems="center"
                            gap="5px"
                            margin="0 0 10px 0"
                          >
                            <Button
                              icon={"FiArrowLeft"}
                              type="hollow"
                              onClick={() => {
                                setCurrentResult(
                                  (currentResult - 1 + completedRows.length) %
                                    completedRows.length
                                );
                              }}
                            />
                            <Button
                              icon={"FiArrowRight"}
                              type="hollow"
                              onClick={() => {
                                setCurrentResult(
                                  (currentResult + 1) % completedRows.length
                                );
                              }}
                            />
                          </Row>
                        </Row>
                        <RenderSingleResult
                          request={request}
                          result={get(completedRows, currentResult, {})}
                        />
                      </div>
                    ) : (
                      <Table
                        onChange={(rows) => {
                          setTableData({ ...tableData, rows });
                        }}
                        showSelect
                        margin="15px 0 0 0"
                        rows={pageRows2}
                        allRows={tableRows}
                        columns={tableColumns}
                        page={page}
                        setPage={setPage}
                        pageCount={pageCount}
                      />
                    )}
                  </>
                )}
              </div>
            )}
          </>
        )}
      </Container>
    </InternalWrapper>
  );
};

export default Session;

export const RenderSingleResult = ({ request, result, manualData }) => {
  const outputHtml = get(request, "output_html");

  // RENDER HTML WITH VARIABLES
  if (outputHtml) {
    const filteredHtmlString = outputHtml.replace(/<p><br><\/p>/g, "");

    const processedHtml = processDynamicText(filteredHtmlString, {
      ...manualData,
      ...result,
    });

    return <HtmlContainer>{parse(processedHtml)}</HtmlContainer>;
  }

  // RENDER REGULAR KEY/VALUE PAIRS
  return (
    <ResultContainer>
      {get(request, "fields", []).map((f) => (
        <div>
          <Text
            value={startCase(f.key)}
            bold
            margin="0 0 5px 0"
            fontSize={20}
          />
          <Text value={get(result, f.key)} fontSize={20} />
        </div>
      ))}
    </ResultContainer>
  );
};

const MethodOption = styled.div`
  display: flex;
  gap: 20px;
  flex-direction: column;
  width: 300px;
  background: white;
  border-radius: 20px;
  padding: 30px;
  border: 2px solid ${colors.inputBorder};
  cursor: pointer;
  transition: 0.2s;
  &:hover {
    background: ${colors.inputBorder};
  }
`;

const Container = styled.div`
  display: flex;
  gap: 20px;
  flex-direction: column;
  width: 100%;
`;

const HtmlContainer = styled.div`
  background: white;
  border-radius: 10px;
  padding: 5px 20px 5px 20px;
  border: 2px solid ${colors.inputBorder};
`;

const ResultContainer = styled.div`
  display: flex;
  // gap: 20px;
  flex-direction: column;
  width: 100%;
  background: white;
  border-radius: 10px;
  padding: 20px;
  border: 2px solid ${colors.inputBorder};
`;
