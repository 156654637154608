import Icon from "./Icon";
import Text from "./Text";
import { colors } from "utils/color";
import { get } from "lodash";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  margin: ${(p) => p.margin || "0px"};
  flex-direction: row;
  width: ${(p) => p.width || "auto"};
`;

const SearchContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 6px;
  padding-right: 10px;
  background: ${(p) => p.background};
  border: 1px solid ${colors.borderDark};
`;

const SearchInput = styled.input`
  width: 100%;
  border: 0px;
  background: transparent;
  font-size: 15px;
  color: ${colors.grey};
  padding: ${(p) => p.padding || "10px"};
  font-weight: 400;
  font-size: 14px;
  outline: 0px;
  &::placeholder {
    color: ${colors.grey};
  }
`;

const SearchBar = ({
  width = "100%",
  value,
  onChange,
  margin,
  placeholder,
  clear,
  // initialValue,
  submit,
}) => {
  const onEnterPress = (e) => {
    if (e.key === "Enter") {
      submit(value);
    }
  };

  return (
    <Container width={width} margin={margin}>
      <SearchContainer>
        <SearchInput
          placeholder={placeholder || "Search"}
          onChange={(e) => {
            e.stopPropagation();
            onChange(e.target.value);
          }}
          onKeyPress={(e) => onEnterPress(e)}
          value={value}
        />
        {/* {clear && value !== "" && (
          <Text
            value="Clear"
            onClick={() => onChange("")}
            margin="0 20px 0 0"
            color={colors.primary}
          />
        )} */}
        {get(value, "length") && clear ? (
          <Icon
            icon="FiX"
            color={colors.grey}
            onClick={() => onChange("")}
            hover
          />
        ) : (
          <Icon
            icon="FiSearch"
            color={colors.grey}
            onClick={() => submit?.(value, "search")}
            hover
          />
        )}
      </SearchContainer>
    </Container>
  );
};

export default SearchBar;
