import styled from "styled-components";

const Text = ({ value, size, color, children, ...props }) => {
  // Font Weight
  let weight = 400;
  if (props.light) {
    weight = 300;
  } else if (props.medium) {
    weight = 500;
  } else if (props.bold) {
    weight = 600;
  } else if (props.extraBold) {
    weight = 700;
  }

  return (
    <StyledText fontSize={size} fontWeight={weight} color={color} {...props}>
      {value}
    </StyledText>
  );
};

export default Text;

const StyledText = styled.div`
  font-size: ${(p) => p.fontSize || 14}px;
  font-weight: ${(p) => p.fontWeight || 400};
  text-align: ${(p) => p.textAlign || "flex-start"};
  margin: ${(p) => p.margin || "0px"};
  color: ${(p) => p.color || "black"};
  cursor: ${(p) => p.cursor || (p.onClick ? "pointer" : "default")};
  white-space: ${(p) => p.whiteSpace || "normal"};
`;
