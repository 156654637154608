import Text from "./Text";
import { colors } from "utils/color";
import styled from "styled-components";

const List = ({ items = [], emptyText = "No Items" }) => {
  if (items.length === 0) {
    return <Text value={emptyText} fontSize={20} />;
  }

  return (
    <Container>
      {items.map((item, i) => (
        <ItemContainer key={i}>{item}</ItemContainer>
      ))}
    </Container>
  );
};

export default List;

const Container = styled.div`
  border: 2px solid ${colors.inputBorder};
  background: white;
  border-radius: 10px;
  overflow: hidden;
`;

const ItemContainer = styled.div`
  border-top: 2px solid ${colors.inputBorder};
  cursor: pointer;
  &:first-child {
    border: 0px;
  }
`;
