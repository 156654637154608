import { atom } from "recoil";

export const rUser = atom({
  key: "user",
  default: null,
});

export const rAccount = atom({
  key: "account",
  default: null,
});

export const rIsFetchingUser = atom({
  key: "isFetchingUser",
  default: false,
});

export const rWebsocket = atom({
  key: "websocket",
  default: null,
});

export const rWebsocketRequests = atom({
  key: "websocketRequests",
  default: [],
});

export const rRunId = atom({
  key: "runId",
  default: null,
});
