import { colors } from "utils/color";
import styled from "styled-components";

const Tabs = ({ tabs, margin }) => {
  return (
    <Container margin={margin}>
      {tabs.map((tab) => (
        <Tab active={tab.active} onClick={tab.onClick}>
          {tab.label}
        </Tab>
      ))}
    </Container>
  );
};

export default Tabs;

const Tab = styled.div`
  font-size: 18px;
  font-weight: 500;
  padding: 16px;
  cursor: pointer;
  border-bottom: 3px solid
    ${(p) => (p.active ? colors.primaryDark : "transparent")};
`;

const Container = styled.div`
  display: flex;
  border-bottom: 1px solid ${colors.inputBorder};
  margin: ${(p) => p.margin};
`;
