import "react-notifications-component/dist/theme.css";

import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { rAccount, rUser } from "utils/recoil";
import { useEffect, useState } from "react";

import Billing from "pages/internal/Billing";
import Cookies from "js-cookie";
import LoadingScreen from "components/LoadingScreen";
import Login from "pages/public/Login";
import { ReactNotifications } from "react-notifications-component";
import RequestDetails from "pages/internal/RequestDetails";
import Requests from "pages/internal/Requests";
import Session from "pages/internal/Session";
import Settings from "pages/internal/Settings";
import VerifyEmail from "pages/public/VerifyEmail";
import { apiRequest } from "utils/api";
import { get } from "lodash";
import { useRecoilState } from "recoil";

export const publicRoutes = ["/login", "/signup", "/verify-email"];

const App = () => {
  const [user, setUser] = useRecoilState(rUser);
  const [account, setAccount] = useRecoilState(rAccount);

  const [isFetchingConfig, setIsFetchingConfig] = useState(false);

  const navigate = useNavigate();

  const { pathname } = useLocation();

  const isPublicRoute = publicRoutes.includes(pathname);

  const isLoading = !isPublicRoute && (isFetchingConfig || !account || !user);

  useEffect(() => {
    // If private route, fetch account
    if (!isPublicRoute && !user) {
      // If no cookie, redirect to login
      const token = Cookies.get("accessToken");

      if (token) {
        setIsFetchingConfig(true);

        // Fetch account
        apiRequest.get("/config/").then((res) => {
          const response = get(res, "data");

          const user = get(response, "user", {});
          setUser(user);

          const account = get(response, "account", {});
          setAccount(account);

          setIsFetchingConfig(false);
        });
      } else {
        navigate("/login");
      }
    }
  }, [pathname]);

  if (isLoading) {
    return <LoadingScreen />;
  }

  return (
    <>
      <ReactNotifications style={{ zIndex: 2000 }} />
      <Routes>
        {/* INTERNAL ROUTES */}
        <Route path="requests" element={<Requests />} />
        <Route path="session/:id" element={<Session />} />
        <Route path="request/:id" element={<RequestDetails />} />
        <Route path="billing" element={<Billing />} />
        {/* <Route path="settings" element={<Settings />} /> */}
        {/* PUBLIC ROUTES */}
        <Route path="/" element={<Login type="login" />} />
        <Route path="login" element={<Login type="login" />} />
        <Route path="signup" element={<Login type="signup" />} />
        <Route path="verify-email" element={<VerifyEmail />} />
      </Routes>
    </>
  );
};

export default App;
