// import * as bootstrap from "react-icons/bs";
import * as feather from "react-icons/fi";

import { get } from "lodash";

// import * as hero from "react-icons/hi2";

const Icon = ({
  icon,
  size = 20,
  color = "white",
  onClick,
  hover,
  margin = "0px",
  disabled,
}) => {
  const props = {
    size,
    color,
    style: {
      cursor: hover && "pointer",
      margin,
      opacity: disabled ? "0.6" : "1.0",
    },
  };

  const combinedIcons = {
    // ...bootstrap,
    ...feather,
    // ...hero
  };

  if (icon) {
    const NewIcon = get(combinedIcons, icon);
    if (NewIcon) {
      return <NewIcon onClick={onClick} {...props} />;
    }
  }

  return null;
};

export default Icon;
