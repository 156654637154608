import { Form, List, RichText, Spinner, Text } from "components";
import { get, isEmpty } from "lodash";
import { getUrlParameter, getVariablesFromString } from "utils/utils";
import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import Dropdown from "components/Dropdown";
import Examples from "components/Examples";
import InternalWrapper from "components/InternalWrapper";
import KeyValuePairs from "components/KeyValuePairs";
import Tabs from "components/Tabs";
import { apiRequest } from "utils/api";
import { colors } from "utils/color";
import moment from "moment";
import { rUser } from "utils/recoil";
import styled from "styled-components";
import { successNotification } from "utils/notification";
import { useRecoilValue } from "recoil";

const RequestDetails = () => {
  const user = useRecoilValue(rUser);

  const [request, setRequest] = useState(null);
  const [isFetching, setIsFetching] = useState(false);
  const [isCreating, setIsCreating] = useState(false);
  const [changeObj, setChangeObj] = useState({});

  const variables = getVariablesFromString(get(request, "prompt", ""));

  const sessions = get(request, "sessions", []) || [];

  const location = useLocation();

  const tab = getUrlParameter("tab", location) || "details";

  const { id } = useParams();

  const navigate = useNavigate();

  const save = () => {
    setIsFetching(true);
    apiRequest.post("/request_details/", { ...changeObj, id });
    setIsFetching(false);
    successNotification("Changes saved");
    setChangeObj({});
  };

  const deleteRequest = () => {
    apiRequest.delete("/request_details/", { data: { id } });
    successNotification("Request deleted");
    navigate("/requests/");
  };

  const getRequest = async () => {
    setIsFetching(true);
    const requestRes = await apiRequest.get(`/request_details/?id=${id}`);
    setRequest(get(requestRes, "data", {}));
    setIsFetching(false);
  };

  useEffect(() => {
    if (user && !request) {
      getRequest();
    }
  }, [user]);

  const fields = get(request, "fields", []) || [];
  const examples = get(request, "examples", []) || [];

  let visibleFields = [
    {
      id: "prompt",
      component: "TextArea",
      minHeight: "100px",
    },
    {
      id: "is_array",
      component: "Switch",
      minHeight: "100px",
    },
  ];

  if (get(request, "is_array")) {
    visibleFields = [
      ...visibleFields,
      {
        id: "array_min_length",
        type: "number",
      },
      {
        id: "array_max_length",
        type: "number",
      },
    ];
  }

  const createNewSession = async () => {
    setIsCreating(true);
    const sessionRes = await apiRequest.post("/sessions/", { request_id: id });
    const newSessionId = get(sessionRes, ["data", "id"]);
    setIsCreating(false);
    navigate(`/session/${newSessionId}`);
  };

  const variableButton = isEmpty(changeObj)
    ? {
        text: "New Session",
        onClick: createNewSession,
        isFetching: isCreating,
      }
    : {
        text: "Save Changes",
        onClick: save,
        disabled: isEmpty(changeObj),
      };

  return (
    <InternalWrapper
      isTitleEditable={true}
      onTitleChange={(v) => {
        setRequest({ ...request, name: v });
        setChangeObj({ ...changeObj, name: v });
      }}
      titlePlaceholder="New Request"
      title={get(request, "name")}
      backRoute="/requests"
      menu={[
        {
          label: "Delete",
          onClick: deleteRequest,
        },
      ]}
      buttons={[variableButton]}
    >
      <Container>
        {isFetching && <Spinner color={colors.primary} />}
        {!isFetching && request && (
          <DetailsWrapper>
            <Tabs
              margin="0 0 30px 0"
              tabs={[
                {
                  label: "Details",
                  active: tab === "details",
                  onClick: () => navigate(`/request/${id}?tab=details`),
                },
                {
                  label: "Sessions",
                  active: tab === "sessions",
                  onClick: () => navigate(`/request/${id}?tab=sessions`),
                },
              ]}
            />
            {(!tab || tab === "details") && (
              <>
                <Text
                  value={"Basic Info"}
                  size={20}
                  bold
                  cursor="pointer"
                  margin="0 0 15px 0"
                />
                <Form
                  onChange={(k, v) => {
                    setRequest({ ...request, [k]: v });
                    setChangeObj({ ...changeObj, [k]: v });
                  }}
                  value={request}
                  fields={visibleFields}
                />
                <Text
                  value={"Fields"}
                  size={20}
                  margin="25px 0 15px 0"
                  bold
                  cursor="pointer"
                />
                <KeyValuePairs
                  margin="15px 0 0 0"
                  label="fields"
                  value={fields}
                  onChange={(v) => {
                    setRequest({ ...request, fields: v });
                    setChangeObj({ ...changeObj, fields: v });
                  }}
                />
                <Text
                  value={"Examples"}
                  size={20}
                  margin="25px 0 15px 0"
                  bold
                  cursor="pointer"
                />
                <Examples
                  examples={examples}
                  variables={variables}
                  fields={fields}
                  onChange={(v) => {
                    setRequest({ ...request, examples: v });
                    setChangeObj({ ...changeObj, examples: v });
                  }}
                />
                <Text
                  value={"Output"}
                  size={20}
                  margin="25px 0 15px 0"
                  bold
                  cursor="pointer"
                />
                <RichText
                  value={get(request, "output_html")}
                  onChange={(v) => {
                    setRequest({ ...request, output_html: v });
                    setChangeObj({ ...changeObj, output_html: v });
                  }}
                />
              </>
            )}
            {tab === "sessions" && (
              <List
                items={sessions.map((r) => (
                  <SessionBubble
                    session={r}
                    onDelete={() => {
                      setRequest({
                        ...request,
                        sessions: sessions.filter((s) => s.id !== r.id),
                      });
                    }}
                  />
                ))}
                emptyText="You haven't run this request yet, so there are no sessions"
              />
            )}
          </DetailsWrapper>
        )}
      </Container>
    </InternalWrapper>
  );
};

export default RequestDetails;

const Container = styled.div`
  display: flex;
  gap: 20px;
  flex-direction: column;
  width: 100%;
`;

const DetailsWrapper = styled.div`
  width: 650px;
`;

const RequestContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 15px;
  width: 100%;
  cursor: pointer;
  background: white;
  padding: 15px;
`;

const SessionBubble = ({ session, onDelete }) => {
  const navigate = useNavigate();

  const deleteSession = () => {
    apiRequest.delete("/session_details/", { data: { id: session.id } });
    successNotification("Session deleted");
    onDelete();
  };

  return (
    <RequestContainer onClick={() => navigate(`/session/${session.id}`)}>
      <div>
        <Text
          value={session.name}
          size={24}
          bold
          cursor="pointer"
          margin="0 0 5px 0"
        />
        <Text
          value={moment(session.created_at).format("MMMM D, YYYY h:mm A")}
          size={16}
          light
          cursor="pointer"
        />
      </div>
      <Dropdown
        icon={{
          icon: "FiMoreHorizontal",
          size: 25,
          color: colors.grey,
          margin: "0 15px 0 0",
          hover: true,
        }}
        options={[
          {
            label: "Delete",
            onClick: deleteSession,
          },
        ]}
      />
    </RequestContainer>
  );
};
