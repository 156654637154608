import { rRunId, rWebsocket, rWebsocketRequests } from "./recoil";
import { useCallback, useEffect, useState } from "react";
import { useRecoilState, useRecoilValue } from "recoil";

import { get } from "lodash";

const useWebsocket = (sessionId, callback) => {
  const [websocket, setWebsocket] = useRecoilState(rWebsocket);
  const [history, setHistory] = useState([]);
  const [responses, setResponses] = useState([]);

  const runId = useRecoilValue(rRunId);

  const websocketRequests = useRecoilValue(rWebsocketRequests);
  const shouldConnect = !!sessionId;

  useEffect(() => {
    const toProcess = responses.filter(
      (r) =>
        r.session_id === sessionId &&
        r.run_id === runId &&
        !history.includes(r.uuid)
    );
    toProcess.forEach((r) => processResponse(r));
  }, [responses]);

  const processResponse = (response) => {
    const responseUuid = get(response, "uuid");

    // We already know this response is for this session and run, so we just check the uuid
    const matchingInstance = websocketRequests.find(
      (r) => r.uuid === responseUuid
    );

    if (matchingInstance && !history.includes(responseUuid)) {
      callback(response);
      // Add instanceId to history to prevent duplicate runs
      setHistory((prevHistory) => [...prevHistory, responseUuid]);
    }
  };

  // Function to initialize WebSocket connection
  const connect = useCallback(() => {
    if (shouldConnect && (!websocket || websocket.readyState !== 1)) {
      // TODO - Pass session ID to ensure no overlap
      const wsURL = `wss://o0v9pmvz0g.execute-api.ca-central-1.amazonaws.com/production`;
      const ws = new WebSocket(wsURL);

      ws.onopen = () => {
        console.log("WebSocket Connected");
        setWebsocket(ws);
      };

      ws.onmessage = (event) => {
        const wsResponse = JSON.parse(event.data);
        setResponses((r) => [...r, wsResponse]);
      };

      ws.onclose = () => {
        console.log("WebSocket Disconnected");
        setWebsocket(null);
      };

      return () => ws.close();
    }
  }, [shouldConnect, websocket]);

  // Function to close WebSocket connection
  const disconnect = useCallback(() => {
    if (websocket) {
      websocket.close();
    }
  }, [websocket]);

  useEffect(() => {
    connect();
    return () => disconnect();
  }, [connect, disconnect]);

  return { connect, disconnect };
};

export default useWebsocket;
