import Button from "./Button";
import Form from "./Form";
import FullScreenModal from "./FullScreenModal";
import Icon from "./Icon";
import Row from "./Row";
import Text from "./Text";
import { colors } from "utils/color";
import { get } from "lodash";
import styled from "styled-components";
import { useState } from "react";

const Examples = ({ examples = [], fields = [], variables = [], onChange }) => {
  const editExample = (index, data) => {
    const newExamples = [...examples];
    newExamples[index] = data;
    onChange(newExamples);
  };

  const deleteExample = (index) => {
    const newExamples = [...examples];
    newExamples.splice(index, 1);
    onChange(newExamples);
  };

  return (
    <div>
      {examples.map((example, index) => (
        <Example
          variables={variables}
          index={index}
          data={example}
          fields={fields}
          onChange={(data) => editExample(index, data)}
          deleteExample={deleteExample}
        />
      ))}
      <Button
        text="Add"
        size="small"
        icon="FiPlus"
        style={{ margin: "10px 0 0 0" }}
        onClick={() =>
          onChange([
            ...examples,
            {
              inputs: {},
              outputs: {},
            },
          ])
        }
      />
    </div>
  );
};

export default Examples;

const Example = ({
  index,
  data = {},
  fields = [],
  variables = [],
  onChange,
  deleteExample,
}) => {
  const [showEdit, setShowEdit] = useState(false);

  const inputData = get(data, "inputs", {});
  const outputData = get(data, "outputs", {});

  return (
    <>
      {showEdit && (
        <FullScreenModal hide={() => setShowEdit(false)} label="Edit Example">
          <Text
            value={"Inputs"}
            size={20}
            bold
            cursor="pointer"
            margin="0 0 15px 0"
          />
          <Form
            onChange={(k, v) =>
              onChange({ ...data, inputs: { ...inputData, [k]: v } })
            }
            value={inputData}
            fields={variables.map((v) => ({
              id: v,
              component: "Input",
            }))}
          />
          <Text
            value={"Outputs"}
            size={20}
            bold
            cursor="pointer"
            margin="30px 0 15px 0"
          />
          <Form
            onChange={(k, v) =>
              onChange({ ...data, outputs: { ...outputData, [k]: v } })
            }
            value={outputData}
            fields={fields.map((f) => ({
              id: f.key,
              value: data[f.key],
              component: "Input",
            }))}
          />
        </FullScreenModal>
      )}
      <ExampleContainer onClick={() => setShowEdit(true)}>
        <Row justifyContent="space-between">
          Example {index + 1}
          <Icon
            icon="FiTrash"
            size={18}
            hover
            color="#bab7a6"
            onClick={() => deleteExample(index)}
          />
        </Row>
      </ExampleContainer>
    </>
  );
};

const ExampleContainer = styled.div`
  padding: 10px;
  margin: 10px 0;
  border-radius: 5px;
  border: 1px solid ${colors.borderDark};
  background: white;
  cursor: pointer;
`;
