import { MenuItem, Select as MuiSelect } from "@mui/material";
import {
  border,
  borderRadius,
  fontColor,
  fontSize,
  padding,
} from "utils/inputs";

import InputBase from "@mui/material/InputBase";
import { colors } from "utils/color";
import { styled } from "@mui/material/styles";

export const BootstrapInput = styled(InputBase)(({ theme, ...props }) => ({
  "label + &": {
    marginTop: theme.spacing(3),
  },
  "& .MuiInputBase-input": {
    borderRadius: borderRadius,
    position: "relative",
    backgroundColor: props.backgroundColor || "white",
    border: props.border || border,
    fontSize: fontSize,
    color: fontColor,
    padding: props.padding || padding,
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:focus": {
      borderRadius,
      borderColor: `2px solid ${colors.darkBeige}`,
      boxShadow: "none",
    },
  },
}));

const Select = ({
  value,
  defaultValue,
  options,
  width,
  margin,
  onChange,
  nullLabel,
}) => {
  return (
    <div style={{ width: width || "100%", margin }}>
      <MuiSelect
        displayEmpty
        renderValue={value ? null : () => "None"}
        value={value}
        sx={{
          width: "100%",
        }}
        input={<BootstrapInput />}
        size="small"
        onChange={(e) => onChange(e.target.value)}
      >
        {nullLabel && <MenuItem value="">{nullLabel}</MenuItem>}
        {options.map((o) => (
          <MenuItem value={o.value}>{o.label}</MenuItem>
        ))}
      </MuiSelect>
    </div>
  );
};

export default Select;
