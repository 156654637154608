import { get, startCase } from "lodash";

import { Checkbox } from "@mui/material";
import Input from "./Input";
import Pagination from "./Pagination";
import Spinner from "./Spinner";
import { colors } from "utils/color";
import styled from "styled-components";

const Table = ({
  allRows,
  rows,
  columns,
  margin,
  showSelect,
  onChange,
  page,
  setPage,
  pageCount,
}) => {
  const allSelected = rows.every((row) => row.f_selected);

  const updateRow = (row, key, value) => {
    const newRows = allRows.map((r) => {
      if (r.f_uuid === row.f_uuid) {
        return { ...r, [key]: value };
      }
      return r;
    });
    onChange(newRows);
  };

  const selectAll = (value) => {
    if (value) {
      const startIndex = (page - 1) * 50;
      const endIndex = startIndex + 50;

      const updatedRowUuids = allRows
        .filter((row, index) => {
          if (index >= startIndex && index < endIndex) {
            return true;
          }
          return false;
        })
        .map((r) => r.f_uuid);

      const updatedRows = allRows.map((r) => {
        if (updatedRowUuids.includes(r.f_uuid)) {
          return { ...r, f_selected: true };
        }
        return { ...r, f_selected: false };
      });

      onChange(updatedRows);
    } else {
      // Deselect all rows
      deselectAll();
    }
  };

  const deselectAll = () => {
    const updatedRows = allRows.map((row) => ({ ...row, f_selected: false }));
    onChange(updatedRows);
  };

  return (
    <div style={{ margin }}>
      <Container>
        <Row alignItems="center">
          {showSelect && (
            <CheckboxCell>
              <Checkbox
                checked={allSelected}
                onChange={() => {
                  if (allSelected) {
                    selectAll(false);
                  } else {
                    selectAll(true);
                  }
                }}
              />
            </CheckboxCell>
          )}
          {columns.map((column, index) => (
            <HeaderCell
              style={{
                borderRight:
                  index === columns.length - 1
                    ? "none"
                    : `1px solid ${colors.inputBorder}`,
              }}
            >
              {startCase(column)}
            </HeaderCell>
          ))}
        </Row>
        {rows.map((row, rowIndex) => {
          const isSelected = row.f_selected ? true : false;

          return (
            <Row>
              {showSelect && (
                <CheckboxCell>
                  <Checkbox
                    checked={isSelected}
                    onChange={() => updateRow(row, "f_selected", !isSelected)}
                  />
                </CheckboxCell>
              )}
              {columns.map((column, index) => {
                const val = get(row, column) || "";
                const generating = get(row, "f_is_generating");
                const showSpinner = !val && generating;

                const columnCount = columns.length;

                if (showSpinner) {
                  return (
                    <SpinnerCell width={`calc(100% / ${columnCount} - 1px)`}>
                      <Spinner color={"#bab7a6"} size={20} />
                    </SpinnerCell>
                  );
                } else {
                  return (
                    <Input
                      width={`calc(100% / ${columnCount} - 1px)`}
                      value={get(row, column) || ""}
                      placeholder={startCase(column)}
                      // onChange={(v) => editField(index, "key", v)}
                      border={`2px solid ${colors.inputBorder}`}
                      borderradius="0px"
                      borderwidth={
                        index === columns.length - 1 ? "0px" : "0px 1px 0px 0px"
                      }
                    />
                  );
                }
              })}
            </Row>
          );
        })}
      </Container>
      <Pagination
        currentPage={page}
        pageCount={pageCount}
        setPage={(v) => {
          deselectAll();
          setPage(v);
        }}
      />
    </div>
  );
};

export default Table;

const CheckboxCell = styled.div`
  width: 34px;
  height: 34px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
  border-right: 1px solid ${colors.inputBorder};
`;

const SpinnerCell = styled.div`
  width: ${(p) => p.width};
  display: flex;
  align-items: center;
  padding: 5px;
  padding-left: 10px;
  border-right: 1px solid ${colors.inputBorder};
  &:last-child {
    border-right: none;
  }
`;

const Container = styled.div`
  border: 2px solid ${colors.inputBorder};
  border-radius: 8px;
  overflow: hidden;
  background-color: rgba(255, 255, 255, 0.5);
`;

const Row = styled.div`
  display: flex;
  border-bottom: 1px solid ${colors.inputBorder};
  &:last-child {
    border-bottom: none;
  }
`;

const HeaderCell = styled.div`
  flex: 1;
  font-size: 13px;
  font-weight: 600;
  padding: 7px 0 7px 10px;
  color: ${colors.darkGrey};
  align-items: center;
`;
