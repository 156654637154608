import { Form } from "components";
import InternalWrapper from "components/InternalWrapper";
import { apiRequest } from "utils/api";
import { isEmpty } from "lodash";
import { rAccount } from "utils/recoil";
import styled from "styled-components";
import { successNotification } from "utils/notification";
import { useRecoilState } from "recoil";
import { useState } from "react";

const Settings = () => {
  const [account, setAccount] = useRecoilState(rAccount);
  const [changeObj, setChangeObj] = useState({});

  const [localState, setLocalState] = useState(account);

  const save = () => {
    apiRequest.post("/account_details/", changeObj);
    successNotification("Changes saved");
    setChangeObj({});
    setAccount({ ...account, ...changeObj });
  };

  return (
    <InternalWrapper title="Settings">
      <Container>
        <Form
          onSubmit={save}
          submitText={"Save Changes"}
          disabled={isEmpty(changeObj)}
          onChange={(k, v) => {
            setLocalState({ ...localState, [k]: v });
            setChangeObj({ ...changeObj, [k]: v });
          }}
          value={localState}
          fields={[
            {
              id: "open_ai_api_key",
            },
          ]}
        />
      </Container>
    </InternalWrapper>
  );
};

export default Settings;

const Container = styled.div`
  display: flex;
  gap: 20px;
  flex-direction: column;
  width: 600px;
  @media (max-width: 800px) {
    width: 100%;
  }
`;
