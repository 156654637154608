import { Row, Text } from "components";

import InternalWrapper from "components/InternalWrapper";
import { rAccount } from "utils/recoil";
import styled from "styled-components";
import { useRecoilState } from "recoil";

const Billing = () => {
  const [account, setAccount] = useRecoilState(rAccount);

  return (
    <InternalWrapper title="Billing & Usage">
      <Container>
        <Row gap={"5px"}>
          <Text value={"Credits Used:"} size={20} fontWeight={400} />
          <Text value={account.credits_used} size={20} fontWeight={600} />
        </Row>

        <Row gap={"5px"}>
          <Text value={"Credits Remaining:"} size={20} fontWeight={400} />
          <Text value={account.credits_remaining} size={20} fontWeight={600} />
        </Row>

        <Text
          value={
            "A credit is the equivalent of 10,000 input tokens or 2,500 output tokens"
          }
          size={14}
          fontWeight={300}
        />
      </Container>
    </InternalWrapper>
  );
};

export default Billing;

const Container = styled.div`
  display: flex;
  gap: 20px;
  flex-direction: column;
  width: 600px;
  @media (max-width: 800px) {
    width: 100%;
  }
`;
