import { colors } from "utils/color";
import styled from "styled-components";

const PublicWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  overflow: auto;
  position: relative;
  background-color: ${colors.primary};
`;

export default PublicWrapper;
