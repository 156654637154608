import { get, startCase } from "lodash";

import Button from "./Button";
import Input from "./Input";
import Select from "./Select";
import Switch from "./Switch";
import Text from "./Text";
import TextArea from "./TextArea";
import styled from "styled-components";

const Form = ({
  fields,
  onChange,
  onSubmit,
  submitText,
  isFetching,
  value,
  gridLayout,
  disabled,
  fullWidthButton = false,
  buttonSize,
}) => {
  return (
    <div style={{ width: "100%" }}>
      <FieldsContainer gridLayout={gridLayout} onSubmit={onSubmit}>
        {fields.map((field) => {
          const fieldValue = field.value || get(value, field.id);
          return (
            <FormField
              {...field}
              value={fieldValue}
              onChange={(v) => onChange(field.id, v)}
            />
          );
        })}
      </FieldsContainer>
      {onSubmit && (
        <Button
          text={submitText || "Submit"}
          onClick={onSubmit}
          fullWidth={fullWidthButton}
          isFetching={isFetching}
          disabled={disabled}
          size={buttonSize}
        />
      )}
    </div>
  );
};

export default Form;

const FieldsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: 100%;
  margin: ${(p) => (p.onSubmit ? "0 0 25px 0" : "0")};
  ${(p) =>
    p.gridLayout &&
    `
    display: grid;
    grid-gap: 15px;
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  `}

  @media (max-width: 800px) {
    display: flex;
    flex-direction: column;
  }
`;

const FormField = ({
  id,
  component = "Input",
  label,
  value,
  onChange,
  type,
  columnSpan = 1,
  ...props
}) => {
  const componentMap = {
    Input,
    TextArea,
    Select,
    Switch,
  };

  const Component = get(componentMap, component);

  return (
    <FormFieldWrapper columnSpan={columnSpan}>
      <Text value={label || startCase(id)} size={16} fontWeight={400} />
      <Component
        value={value}
        onChange={(v) => onChange(v)}
        placeholder={label}
        type={type}
        {...props}
      />
    </FormFieldWrapper>
  );
};

const FormFieldWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  grid-column: ${(p) => `span ${p.columnSpan}`};
`;
