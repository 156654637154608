import { Icon } from "components";
import React from "react";
import { colors } from "utils/color";
import styled from "styled-components";

const PaginationContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  margin-top: 15px;
`;

const PageButton = styled.button`
  padding: 10px;
  min-width: 35px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  border-radius: 10px;
  border: 1px solid
    ${({ active }) => (active ? colors.primary : colors.inputBorder)};
  ${({ disabled }) =>
    disabled &&
    `
    cursor: not-allowed;
    opacity: 0.5;
  `}
`;

const Pagination = ({ currentPage, pageCount, setPage }) => {
  const renderPageNumbers = () => {
    const pages = [];
    const startPage = Math.max(1, currentPage - 2);
    const endPage = Math.min(pageCount, currentPage + 2);

    for (let i = startPage; i <= endPage; i++) {
      pages.push(
        <PageButton
          key={i}
          active={i === parseInt(currentPage)}
          onClick={() => setPage(i)}
        >
          {i}
        </PageButton>
      );
    }
    return pages;
  };

  return (
    <PaginationContainer>
      {currentPage === 1 && (
        <PageButton onClick={() => setPage(parseInt(currentPage) - 1)}>
          <Icon icon={"FiArrowLeft"} color={colors.darkGrey} hover size={16} />
        </PageButton>
      )}
      {renderPageNumbers()}
      {currentPage !== pageCount && (
        <PageButton onClick={() => setPage(parseInt(currentPage) + 1)}>
          <Icon icon={"FiArrowRight"} color={colors.darkGrey} hover size={16} />
        </PageButton>
      )}
    </PaginationContainer>
  );
};

export default Pagination;
