import {
  border,
  borderRadius,
  fontColor,
  fontSize,
  padding,
} from "utils/inputs";

import styled from "styled-components";

export const StyledInput = styled.input`
  padding: ${(p) => p.padding || padding};
  border: ${(p) => p.border || border};
  border-radius: ${(p) => p.borderradius || borderRadius};
  width: ${(p) => p.width || "100%"};
  font-size: ${(p) => p.fontSize || fontSize};
  font-weight: ${(p) => p.fontWeight};
  background: ${(p) => p.background};
  color: ${(p) => p.color || fontColor};
  height: ${(p) => p.height};
  border-width: ${(p) => p.borderwidth};
  &:focus {
    outline: none;
  }
`;

const Input = ({ value, onChange, ...props }) => {
  return (
    <StyledInput
      {...props}
      value={value}
      onChange={(e) => onChange(e.target.value)}
    />
  );
};

export default Input;
