import PublicWrapper from "pages/public/components/PublicWrapper";
import React from "react";
import Spinner from "./Spinner";
import { colors } from "utils/color";

const LoadingScreen = () => {
  return (
    <PublicWrapper>
      <Spinner color={colors.primary} size={30} />
    </PublicWrapper>
  );
};

export default LoadingScreen;
