import { useLocation, useNavigate } from "react-router-dom";

import Button from "./Button";
import Dropdown from "./Dropdown";
import Icon from "./Icon";
import LoadingScreen from "./LoadingScreen";
import Row from "./Row";
import Text from "./Text";
import { colors } from "utils/color";
import { get } from "lodash";
import { rIsFetchingUser } from "utils/recoil";
import styled from "styled-components";
import { useRecoilValue } from "recoil";

const InternalWrapper = ({
  children,
  backFunction,
  backRoute,
  backText,
  isTitleEditable,
  onTitleChange,
  titlePlaceholder,
  title,
  buttons = [],
  menu = [],
}) => {
  const navigate = useNavigate();

  const isFetchingUser = useRecoilValue(rIsFetchingUser);

  const location = useLocation();

  if (isFetchingUser) {
    return <LoadingScreen />;
  }

  const items = [
    {
      label: "Requests",
      route: "requests",
      icon: "FiList",
      onClick: () => navigate("/requests/"),
    },
    {
      label: "Billing",
      icon: "FiCreditCard",
      route: "billing",
      onClick: () => navigate("/billing/"),
    },
    // {
    //   label: "Settings",
    //   icon: "FiSettings",
    //   route: "settings",
    //   onClick: () => navigate("/settings/"),
    // },
  ];

  return (
    <AppContainer>
      {!backFunction && !backRoute && (
        <Navigation>
          <Dropdown
            component={<Logo>F</Logo>}
            options={[
              {
                label: "Logout",
                onClick: () => console.log("logout"),
              },
            ]}
          />
          {items.map((item) => (
            <NavigationItem
              active={get(location, "pathname").includes(item.route)}
              onClick={item.onClick}
            >
              <Icon icon={item.icon} size={25} color={colors.grey} />
              <Text
                value={item.label}
                size={12}
                margin="0 0 5px 0"
                color={colors.grey}
              />
            </NavigationItem>
          ))}
        </Navigation>
      )}
      <Content>
        {(backFunction || backRoute) && (
          <Row
            alignItems="center"
            gap="10px"
            margin="0 0 25px 0"
            onClick={backRoute ? () => navigate(backRoute) : backFunction}
          >
            <Icon
              icon="FiArrowLeft"
              color={colors.darkGrey}
              size={30}
              margin="0 0 0 -4px"
              hover
            />
            {backText && (
              <Text
                value={backText}
                size={22}
                light
                cursor="pointer"
                color={"rgba(0,0,0,0.5)"}
              />
            )}
          </Row>
        )}
        <Row
          alignItems="center"
          justifyContent="space-between"
          margin="0 0 30px 0"
        >
          {isTitleEditable ? (
            <StyledInput
              value={title}
              onChange={(e) => onTitleChange(e.target.value)}
              placeholder={titlePlaceholder}
            />
          ) : (
            <Text value={title} extraBold size={30} />
          )}

          <Row alignItems="center" justifyContent="space-between" gap="15px">
            {menu.length > 0 && (
              <Dropdown
                icon={{
                  icon: "FiMoreHorizontal",
                  size: 25,
                  color: colors.grey,
                  margin: "0 15px 0 0",
                  hover: true,
                }}
                options={menu}
              />
            )}
            {buttons.map((b) => (
              <Button {...b} style={{ fontSize: "30px" }} />
            ))}
          </Row>
        </Row>

        <Container>{children}</Container>
      </Content>
    </AppContainer>
  );
};

export default InternalWrapper;

const StyledInput = styled.input`
  font-size: 30px;
  font-weight: 700;
  color: ${colors.darkGrey};
  border: none;
  background: transparent;
  outline: none;
  cursor: pointer;
  width: 100%;
`;

const NavigationItem = styled.div`
  border-radius: 10px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  gap: 5px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  ${(p) => p.active && `background: rgba(0, 0, 0, 0.05);`}
  &:hover {
    background: rgba(0, 0, 0, 0.02);
  }
`;

const AppContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 50px;
  width: 1100px;
  margin: 0 auto;
  padding: 50px 30px 50px 30px;
  @media (max-width: 1000px) {
    padding: 15px;
    width: 100%;
  }
`;

const Navigation = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
`;

const Content = styled.div`
  width: 100%;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  overflow-y: auto;
  width: 100%;
`;

const Logo = styled.div`
  font-size: 30px;
  font-family: "DM Serif Display", serif;
  font-weight: 400;
  color: white;
  margin: 0px;
  line-height: 28px;
  margin-bottom: 5px;
  background: ${colors.primary};
  height: 50px;
  width: 50px;
  border-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
`;
