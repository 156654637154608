import { isInteger } from "lodash";

export const getUrlParameter = (name, location) => {
  if (!name) {
    return null;
  }

  name = name.replace(/[[]/, "\\[").replace(/[\]]/, "\\]");
  const regex = new RegExp("[\\?&]" + name + "=([^&#]*)");
  const results = regex.exec(location.search);
  let value =
    results === null ? "" : decodeURIComponent(results[1].replace(/\+/g, " "));

  if (value === "true") {
    value = true;
  } else if (value === "false") {
    value = false;
  } else if (isInteger(value)) {
    return parseInt(value);
  }

  return value;
};

export const getVariablesFromString = (inputString) => {
  let variables = [];
  let pattern = /\{\{\s*(.*?)\s*\}\}/g;
  let match;

  // Use a loop to find all matches and their captured groups
  while ((match = pattern.exec(inputString)) !== null) {
    // match[1] contains the captured group which is the variable name
    variables.push(match[1]);
  }

  return variables;
};
