import Button from "./Button";
import Papa from "papaparse";
import { useState } from "react";
import { v4 as uuidv4 } from "uuid";

const CSVUpload = ({ onUpload }) => {
  const [isParsing, setIsParsing] = useState(false);

  const handleChange = (event) => {
    const files = event.target.files;
    const maxFileSize = 25 * 1024 * 1024; // 25 MB MAX FILE SIZE

    if (files && files[0]) {
      if (files[0].size > maxFileSize) {
        // If the file size exceeds the limit, show an alert or handle as needed.
        alert("File size should not exceed 5MB.");
        return; // Stop the function here.
      }

      setIsParsing(true);
      parseCSV(files[0]);
    }
  };

  const parseCSV = (file) => {
    Papa.parse(file, {
      complete: function (results) {
        const headers = results.meta.fields.filter(
          (header) => header && !header.match(/^_?\d*$/)
        );
        const rows = results.data.map((row) => {
          const filteredRow = Object.entries(row).reduce(
            (acc, [key, value]) => {
              // Exclude columns with empty keys or keys that look like "_1", "_2", etc.
              if (key && !key.match(/^_?\d*$/)) {
                acc[key] = value;
              }
              return acc;
            },
            {}
          );

          // Add a unique ID to each row.
          return { ...filteredRow, f_uuid: uuidv4() };
        });

        const tableObject = {
          headers,
          rows,
        };

        setIsParsing(false);
        if (onUpload) onUpload(tableObject);
      },
      header: true, // Set to true if your CSV has header rows, it'll parse them as object keys.
      skipEmptyLines: true, // Skips empty lines in the CSV file to ensure clean data.
      error: function (error) {
        setIsParsing(false);
        console.error("Error parsing CSV:", error);
      },
    });
  };

  return (
    <>
      <input
        type="file"
        accept=".csv"
        onChange={handleChange}
        style={{ display: "none" }} // Hiding the default input and triggering click via Button for better UI
        id="csvInput"
      />
      <Button
        isFetching={isParsing}
        text={isParsing ? "Parsing..." : "Click to upload CSV file"}
        icon="FiUploadCloud"
        onClick={() => document.getElementById("csvInput").click()}
        size="large"
      />
    </>
  );
};

export default CSVUpload;
