import { get, truncate } from "lodash";
import { useEffect, useState } from "react";

import InternalWrapper from "components/InternalWrapper";
import List from "components/List";
import Spinner from "components/Spinner";
import Text from "components/Text";
import { apiRequest } from "utils/api";
import { colors } from "utils/color";
import { rUser } from "utils/recoil";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";

const Requests = () => {
  const user = useRecoilValue(rUser);
  const [requests, setRequests] = useState([]);
  const [isFetching, setIsFetching] = useState(false);
  const [isCreating, setIsCreating] = useState(false);

  const navigate = useNavigate();

  const getRequests = async () => {
    setIsFetching(true);
    const requestsRes = await apiRequest.get("/requests/");
    setRequests(get(requestsRes, "data", []));
    setIsFetching(false);
  };

  useEffect(() => {
    if (user && requests.length === 0) {
      getRequests();
    }
  }, [user]);

  const createNewRequest = async () => {
    setIsCreating(true);
    const requestsRes = await apiRequest.post("/requests/");
    const newRequestId = get(requestsRes, ["data", "id"]);
    setIsCreating(false);
    navigate(`/request/${newRequestId}`);
  };

  return (
    <InternalWrapper
      title="Requests"
      buttons={[
        {
          text: "New Request",
          onClick: createNewRequest,
          type: "primary",
          isFetching: isCreating,
        },
      ]}
    >
      <Container>
        {isFetching && <Spinner color={colors.primary} />}
        {!isFetching && (
          <List
            items={requests.map((p) => (
              <Request data={p} />
            ))}
            emptyText="No Requests"
          />
        )}
      </Container>
    </InternalWrapper>
  );
};

export default Requests;

const Container = styled.div`
  display: flex;
  gap: 20px;
  flex-direction: column;
  width: 100%;
`;

const RequestContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 15px;
  width: 100%;
  cursor: pointer;
  background: white;
  padding: 15px;
`;

const Request = ({ data }) => {
  const navigate = useNavigate();
  return (
    <RequestContainer onClick={() => navigate(`/request/${data.id}`)}>
      <div>
        <Text
          value={data.name}
          size={20}
          bold
          cursor="pointer"
          margin="0 0 5px 0"
        />
        <Text
          value={truncate(data.prompt || "No prompt", {
            length: 100,
            separator: " ",
          })}
          size={16}
          cursor="pointer"
        />
      </div>
    </RequestContainer>
  );
};
