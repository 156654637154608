import { get, startCase } from "lodash";

import Cookies from "js-cookie";
import Form from "components/Form";
import { Icon } from "components";
import PublicWrapper from "./components/PublicWrapper";
import Row from "components/Row";
import Text from "components/Text";
import { apiRequest } from "utils/api";
import { colors } from "utils/color";
import { errorNotification } from "utils/notification";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { useState } from "react";

const Login = ({ type }) => {
  const [formData, setFormData] = useState({});

  const [isFetching, setIsFetching] = useState(false);

  const navigate = useNavigate();

  const submit = () => {
    if (
      !formData.email ||
      !formData.email.includes("@") ||
      !formData.email.includes(".") ||
      !formData.password
    ) {
      errorNotification("Please fill out all fields");
      return;
    }

    if (type === "signup" && (!formData.first_name || !formData.last_name)) {
      errorNotification("Please fill out all fields");
      return;
    }

    setIsFetching(true);
    apiRequest.post(`/${type}/`, formData).then((r) => {
      const token = get(r, ["data", "token"], {});
      // const error = get(r, ["data", "error"], {});
      if (token) {
        Cookies.set("accessToken", token, { expires: 30 });
        navigate("/requests");
      } else {
        errorNotification("Invalid email or password");
      }
      setIsFetching(false);
    });
  };

  let formFields = [
    {
      id: "email",
      component: "Input",
      label: "Email",
      columnSpan: 2,
    },
    {
      id: "password",
      component: "Input",
      label: "Password",
      type: "password",
      columnSpan: 2,
    },
  ];

  if (type === "signup") {
    formFields = [
      {
        id: "first_name",
        component: "Input",
        label: "First Name",
        columnSpan: 1,
      },
      {
        id: "last_name",
        component: "Input",
        label: "Last Name",
        columnSpan: 1,
      },
      ...formFields,
    ];
  }

  const features = [
    "Run bulk AI requests on your CSV files",
    "Generate summaries, insights and new content",
    "Streamline time-consuming workflows with large data sets",
  ];

  return (
    <PublicWrapper>
      <Container>
        <LeftContainer>
          <Headline>
            {type === "login" ? "Login To Formattr" : "Welcome To Formattr"}
          </Headline>
          <Subheadline>
            Integrate AI into your daily workflows with ease
          </Subheadline>

          <FeaturesColumn>
            {features.map((f) => (
              <Row alignItems="center" gap="15px">
                <Icon icon="FiCheck" color={colors.ultraLightGrey} size={16} />
                <Text value={f} size={18} color={colors.ultraLightGrey} light />
              </Row>
            ))}
          </FeaturesColumn>
        </LeftContainer>
        <Fields>
          <Logo>Formattr</Logo>
          <Form
            fields={formFields}
            onChange={(k, v) => setFormData({ ...formData, [k]: v })}
            isFetching={isFetching}
            onSubmit={submit}
            submitText={startCase(type)}
            value={formData}
            gridLayout
            fullWidthButton
            buttonSize="large"
          />

          <Row justifyContent="center" gap="10px">
            <Text
              value={
                type === "login"
                  ? "Don't have an account?"
                  : "Already have an account?"
              }
              color={colors.grey}
            />
            <Text
              value={type === "login" ? "Sign Up" : "Login"}
              bold
              onClick={() => navigate(type === "login" ? "/signup" : "/login")}
              color={colors.primaryDark}
            />
          </Row>
        </Fields>
      </Container>
    </PublicWrapper>
  );
};

export default Login;

const FeaturesColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  @media (max-width: 800px) {
    display: none;
  }
`;

const LeftContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 500px;
  @media (max-width: 800px) {
    width: 100%;
  }
`;

const Container = styled.div`
  padding: 30px;
  display: flex;
  justify-content: center;
  width: 100%;
  gap: 100px;
  @media (max-width: 800px) {
    flex-direction: column;
    gap: 10px;
  }
`;

const Fields = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
  width: 390px;
  background: white;
  padding: 40px;
  border-radius: 15px;
  @media (max-width: 800px) {
    width: 100%;
    padding: 20px;
  }
`;

const Logo = styled.div`
  font-size: 46px;
  font-family: "DM Serif Display", serif;
  font-weight: 400;
  color: ${colors.primary};
  @media (max-width: 800px) {
    font-size: 36px;
  }
`;

const Headline = styled.div`
  font-size: 52px;
  font-family: "DM Serif Display", serif;
  font-weight: 400;
  color: white;
  margin-bottom: 20px;
  @media (max-width: 800px) {
    font-size: 38px;
    text-align: center;
  }
`;

const Subheadline = styled.div`
  font-size: 18px;
  color: ${colors.ultraLightGrey};
  margin-bottom: 30px;
  font-weight: 300;
  @media (max-width: 800px) {
    font-size: 16px;
    text-align: center;
  }
`;
