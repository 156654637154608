import Icon from "./Icon";
import React from "react";
import Spinner from "./Spinner";
import { colors } from "utils/color";
import { get } from "lodash";
import styled from "styled-components";

const StyledButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
  letter-spacing: 0px;
  border: ${(p) => p.border || "0px"};
  border-radius: ${(p) => p.borderRadius || "8px"};
  gap: 6px;
  width: ${(p) => p.width || (p.fullWidth ? "100%" : "fit-content")};
  margin: ${(p) => p.margin};
  opacity: ${(p) => p.opacity || (p.disabled ? "0.5" : "1.0")};
  cursor: ${(p) => (p.disabled ? "not-allowed" : "pointer")};
  background: ${(p) => p.background};
  padding: ${(p) => p.padding || "10px"};
  font-size: ${(p) => p.fontSize};
  color: ${(p) => p.color};
  &:hover {
    filter: brightness(90%);
  }
`;

const Button = ({
  text,
  onClick,
  icon,
  style,
  disabled,
  isFetching = false,
  type = "primary",
  size = "medium",
  fullWidth = false,
  padding,
}) => {
  let styles = {
    primary: {
      background: colors.primary,
      color: "white",
    },
    primaryDisabled: {
      background: colors.primary,
      color: "white",
      opacity: "0.5",
    },
    hollow: {
      background: "white",
      color: "#222222",
      border: `1px solid ${colors.borderDark}`,
    },
    secondary: {
      background: "#e9ebf0",
      color: "#222222",
    },
  };

  const matchingStyle = get(styles, type, {});
  const styleObj = { ...matchingStyle, ...style };

  const sizes = {
    small: {
      padding: 7,
      spinner: 16,
      font: 12,
    },
    medium: {
      padding: 12,
      spinner: 20,
      font: 14,
    },
    large: {
      padding: 16,
      spinner: 24,
      font: 18,
    },
  };

  const sizeObj = get(sizes, size, {});

  return (
    <StyledButton
      fullWidth={fullWidth}
      disabled={disabled}
      {...styleObj}
      fontSize={`${get(sizeObj, "font")}px`}
      padding={padding || `${get(sizeObj, "padding")}px`}
      onClick={disabled ? null : onClick}
    >
      {isFetching ? (
        <Spinner
          color={get(styleObj, "color")}
          size={get(sizeObj, "spinner")}
        />
      ) : (
        <>
          <>{text}</>
          {icon && (
            <Icon
              icon={icon}
              color={get(styleObj, "color")}
              size={get(sizeObj, "font")}
            />
          )}
        </>
      )}
    </StyledButton>
  );
};

export default Button;
