import "react-quill/dist/quill.snow.css";

import { useMemo, useState } from "react";

import ReactQuill from "react-quill";
import { colors } from "utils/color";

const RichText = ({ value, onChange, placeholder }) => {
  const modules = useMemo(
    () => ({
      clipboard: {
        matchVisual: false,
      },
      toolbar: [
        [{ header: [1, 2, 3, 4, 5, 6, false] }],
        [
          "header",
          "font",
          "size",
          "bold",
          "italic",
          "strike",
          "underline",
          "blockquote",
          "list",
          "bullet",
          "indent",
          "link",
          {
            color: [
              "#000000",
              "#FFFFFF",
              "#808080",
              "#FF0000",
              "#0000FF",
              "#008000",
              "#FFFF00",
              "#800080",
              "#00FFFF",
              "#FFA500",
            ],
          },
        ],
      ],
    }),
    []
  );

  const formats = useMemo(
    () => [
      "header",
      "font",
      "size",
      "bold",
      "italic",
      "strike",
      "underline",
      "blockquote",
      "list",
      "bullet",
      "indent",
      "link",
      // "image",
      "color",
    ],
    []
  );

  const handleChange = (text) => {
    onChange(text);
  };

  const [edit, setEdit] = useState(false);

  return (
    <>
      <style>
        {/* Overriding React Quill CSS */}
        {`
              .ql-toolbar.ql-snow {
                font-family: "Roboto", "Helvetica", "Helvetica Neue", sans-serif;
                border-radius: 4px 4px 0 0;
                border: 2px solid ${colors.inputBorder};
              }
              .ql-container.ql-snow {
                border: 2px solid ${colors.inputBorder};
                border-radius: 0 0 4px 4px;
              }
              .ql-toolbar.ql-snow .ql-picker-label {
                border: 2px solid ${colors.inputBorder};
                border-radius: 4px;
              }
              .ql-snow .ql-picker {
                color: ${colors.default};
              }
              .ql-container, .ql-container > .ql-editor, .ql-snow, .ql-container.ql-snow {
                line-height: initial;
                font-size: initial;
              }
              .ql-formats { margin: 0px !important; }
            `}
      </style>
      <div style={{ background: "white" }}>
        <ReactQuill
          id="editor"
          modules={modules}
          formats={formats}
          value={value}
          onKeyDown={(e) => e.stopPropagation()}
          onChange={handleChange}
          placeholder={placeholder || "Enter your text..."}
        />
      </div>
    </>
  );
};

export default RichText;
